import { SideBarItem } from "../../../interfaces/SideBarItem";
import AssignmentIcon from '@material-ui/icons/Assignment';



const News: SideBarItem = {
	name: "News",
	icon: <AssignmentIcon />,
	adminPanelURL: "news",
	fetchAllURL: "news/all",
	fetchSingleURL: "news/:id",
	updateURL: "news/:id",
	deleteURL: "news/:id",
	postURL: "news",
	search: true,
	columns: [
		{ id: "headline", label: "Title", type: "Text", isHeadCell: true, required: true },
		{
			id: "datetime",
			label: "Datetime",
            type:"datetime-local",
			isHeadCell: true,
			required: true,
			disabled:true
		}
	],
	orderBy: "datetime",
	order: "desc",
};

export default News;
