import { SideBarItem } from "../../../interfaces/SideBarItem";
import SpeakerIcon from "@material-ui/icons/Speaker";

const LiveAudioStream: SideBarItem = {
	name: "Live Audio Stream",
	icon: <SpeakerIcon />,
	adminPanelURL: "liveAudioStreams",
	fetchAllURL: "liveAudioStreams/all",
	fetchSingleURL: "liveAudioStreams/:id",
	updateURL: "liveAudioStreams/:id",
	deleteURL: "liveAudioStreams/:id",
	postURL: "liveAudioStreams",
	columns: [
		{ id: "title", label: "Title", type: "Text", isHeadCell: true, required: true },
		{
			id: "channelID",
			label: "Channel",
			type: "ReferenceInput",
			isHeadCell: true,
			required: true,
			referenceInputData: { keyInFetchResponse: "channel", referenceURL: "channels/all", referenceID: "id", referenceName: "name" },
		},
		{ id: "description", label: "Description", type: "TextArea", isHeadCell: false, required: true },
		{ id: "image", label: "Image", type: "Image", isHeadCell: false, required: false },
		{ id: "start", label: "Start", type: "Timestamp", isHeadCell: true, required: true },
		{ id: "end", label: "End", type: "Timestamp", isHeadCell: true, required: true },
	],
	orderBy: "name",
	order: "asc",
	blockCreate: false,
	blockDelete: false,
};

export default LiveAudioStream;
