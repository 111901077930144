import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";

//SEN Components
import { SideBarItem } from "../../interfaces/SideBarItem";
import { put, get, apiDelete } from "../../dataProvider";
import ImageUploader from "../sub-components/ImageUploader";

const styles = () =>
	createStyles({
		root: {
			background: "white",
			color: "black",
			borderRadius: "5px",
			padding: "20px",
			marginLeft: "20px",
			boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
		},
		rootMobile: {
			background: "white",
			color: "black",
			borderRadius: "5px",
			padding: "20px",
			boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
		},
		marginBottom20: {
			marginBottom: "20px",
		},
		marginTopBottom20: {
			margin: "20px 0px",
		},
		textField: {
			marginBottom: "20px",
			marginRight: "20px",
		},
		maxWidth50: {
			maxWidth: "50%",
			width: "400px",
		},
		w100: {
			width: "100%",
		},
		p10: {
			padding: "10px",
		},
		inputBox: {
			borderRadius: "5px 5px 0px 0px",
			width: "100%",
			marginBottom: "10px",
		},
		inputPadding: {
			paddingTop: "10px",
			paddingLeft: "10px",
		},
		filled: {
			background: "#E8E8E8",
			paddingTop: "5px",
			paddingLeft: "10px",
		},
	});

interface EditViewProps {
	classes?: any;
	setEdit?: (id: string) => void;
	element: SideBarItem;
	id?: string;
	readOnly: boolean;
	blockDelete: boolean;
	isMobile: boolean;
}

interface EditViewState {
	uploadingImage: number;
	newData: any;
	imageUploading: string;
	loading: boolean;
	selectableOptions: any;
}

class EditView extends React.Component<EditViewProps, EditViewState> {
	constructor(props: EditViewProps) {
		super(props);
		this.state = {
			uploadingImage: 0,
			newData: {},
			imageUploading: "",
			loading: true,
			selectableOptions: {},
		};
		this.getSignedUrl = this.getSignedUrl.bind(this);
		this.onUploadProgress = this.onUploadProgress.bind(this);
		this.onUploadFinish = this.onUploadFinish.bind(this);
		this.onUploadError = this.onUploadError.bind(this);
		this.saveView = this.saveView.bind(this);
		this.deleteView = this.deleteView.bind(this);
		this.updateValues = this.updateValues.bind(this);
		this.updateDateTimeTime = this.updateDateTimeTime.bind(this);
		this.updateDateTimeDate = this.updateDateTimeDate.bind(this);
	}

	async getSignedUrl(file: any, callback: any) {
		const data = await put(`${process.env.REACT_APP_API_URL}/s3`, {
			objectName: file.name,
			contentType: file.type,
		});
		this.setState({ imageUploading: data.data.publicUrl });
		callback(data.data);
	}

	onUploadProgress(prog: number) {
		this.setState({ uploadingImage: prog });
	}

	onUploadFinish(url: string, colId: any) {
		let currentStateOfNewData = this.state.newData;
		currentStateOfNewData[colId] = url;
		this.setState({
			uploadingImage: 100,
			imageUploading: "",
			newData: currentStateOfNewData,
		});
	}

	onUploadError(e: any) {}

	async deleteView() {
		if (window.confirm("Are you sure you want to delete this item?")) {
			await apiDelete(`${process.env.REACT_APP_API_URL}/${this.props.element.deleteURL!.replace(":id", String(this.props.id))}`);
			window.location.pathname = "/admin";
		}
	}

	async saveView() {
		for (const column of this.props.element.columns!) {
			if (column.required) {
				if (!this.state.newData[column.id]) {
					alert(`Please enter a value for the ${column.label}`);
					return;
				}
			}
		}

		//Format ONLY IF PASSES TEST
		for (const column of this.props.element.columns!) {
			if (column.type === "Timestamp") {
				if (this.state.newData[column.id]) {
					console.log(Math.floor(new Date(this.state.newData[column.id]).getTime() / 1000));
					this.state.newData[column.id] = Math.floor(new Date(this.state.newData[column.id]).getTime() / 1000);
				}
			}
		}

		await put(`${process.env.REACT_APP_API_URL}/${this.props.element.updateURL!.replace(":id", String(this.props.id))}`, this.state.newData);
		window.location.href = window.location.href.split("edit/")[0];
	}

	updateValues(id: any, newValue: any) {
		let currentState = this.state.newData;
		currentState[String(id)] = newValue;
		this.setState({ newData: currentState });
	}

	updateDateTimeDate(id: any, newValue: any) {
		if (newValue instanceof Date && !isNaN(newValue as any)) {
			let currentState = this.state.newData;
			let currentDTimeDate = new Date(currentState[String(id)]);

			let newDTimeDate = new Date(newValue);

			newDTimeDate.setHours(currentDTimeDate.getHours());
			newDTimeDate.setMinutes(currentDTimeDate.getMinutes());

			currentState[String(id)] = newDTimeDate;
			this.setState({ newData: currentState });
		}
	}

	updateDateTimeTime(id: any, newValue: any) {
		if (newValue instanceof Date && !isNaN(newValue as any)) {
			let currentState = this.state.newData;
			let currentDTimeDate = new Date(currentState[String(id)]);

			let newDTimeDate = new Date(newValue);

			currentDTimeDate.setHours(newDTimeDate.getHours());
			currentDTimeDate.setMinutes(newDTimeDate.getMinutes());

			currentState[String(id)] = currentDTimeDate;
			this.setState({ newData: currentState });
		}
	}

	//Populate existing data & data that you can select
	async componentDidMount() {
		this.setState({ loading: true });
		let selectableOptions = this.state.selectableOptions;
		let currentData = this.state.newData;

		const data = await get(`${process.env.REACT_APP_API_URL}/${this.props.element.fetchSingleURL!.replace(":id", String(this.props.id))}`);
		for (const column of this.props.element.columns!) {
			if (column.type === "Timestamp") {
				currentData[String(column.id)] = new Date(data.data[column.id] * 1000);
			} else if (column.type === "ManyToMany") {
				const selectableOptionsResponse = await get(`${process.env.REACT_APP_API_URL}/${column.referenceInputData!.referenceURL}`);
				//Get items you can select
				let d = [];
				for (const row of selectableOptionsResponse.data) {
					d.push({
						id: row[column.referenceInputData!.referenceID],
						name: row[column.referenceInputData!.referenceName],
					});
				}
				selectableOptions[column.id] = d;

				//Make array for already selected items
				let flatSelections = [];
				for (const selection of data.data[column.referenceInputData!.keyInFetchResponse]) {
					flatSelections.push(selection.id || selection.mediaID);
				}
				currentData[String(column.id)] = flatSelections;
			} else if (column.type === "ReferenceInput") {
				const selectableOptionsResponse = await get(`${process.env.REACT_APP_API_URL}/${column.referenceInputData!.referenceURL}`);
				//Get items you can select
				let d = [];
				for (const row of selectableOptionsResponse.data) {
					d.push({
						id: row[column.referenceInputData!.referenceID],
						name: row[column.referenceInputData!.referenceName],
					});
				}
				selectableOptions[column.id] = d;
				currentData[String(column.id)] = data.data[column.id];
			} else {
				currentData[String(column.id)] = data.data[column.id];
			}
		}
		this.setState({
			newData: currentData,
			loading: false,
			selectableOptions,
		});
	}

	// Function to convert Unix timestamp to datetime format compatible with datetime-local type
	convertUnixTimestampToDateTime = (timestamp:any) => {
		const date = new Date(parseInt(timestamp) * 1000); 
		const formattedDateTime = date.toISOString().slice(0, 16);
		return formattedDateTime;
	}
	

	render() {
		const { classes, element, readOnly, blockDelete, isMobile } = this.props;
		return (
			<>
				<Container maxWidth="xl" className={!isMobile ? classes.root : classes.rootMobile}>
					{this.state.loading ? (
						<div
							style={{
								height: "80vh",
								textAlign: "center",
								paddingTop: "35vh",
							}}
						>
							<CircularProgress color="secondary" />
						</div>
					) : (
						<>
							{" "}
							<div className={classes.marginBottom20}>
								<h1
									style={{
										marginTop: 0,
										width: "80%",
										float: "left",
									}}
								>
									Editing {element.name}
								</h1>
								{!readOnly && (
									<>
										{!blockDelete && (
											<IconButton aria-label="delete" style={{ float: "right" }} onClick={() => this.deleteView()}>
												<DeleteIcon />
											</IconButton>
										)}
										<IconButton aria-label="" style={{ float: "right" }} onClick={() => this.saveView()}>
											<SaveIcon />
										</IconButton>
									</>
								)}
							</div>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									{element.columns!.map((column, index) => (
										<>
											{column.type === "Text" && (
												<TextField
													value={this.state.newData[column.id]}
													label={column.label}
													variant="filled"
													className={classes.inputBox}
													onChange={(event) => this.updateValues(column.id, event.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													disabled={column.disabled}
												/>
											)}
											{column.type === "TextArea" && (
												<TextField
													value={this.state.newData[column.id]}
													variant="filled"
													multiline
													rows={5}
													label={column.label}
													className={classes.inputBox}
													onChange={(event) => this.updateValues(column.id, event.target.value)}
													InputLabelProps={{
														shrink: true,
													}}
													disabled={readOnly}
												/>
											)}
											{column.type === "Number" && (
												<TextField
													value={this.state.newData[column.id]}
													variant="filled"
													label={column.label}
													type="number"
													InputLabelProps={{
														shrink: true,
													}}
													className={classes.inputBox}
													onChange={(event) => this.updateValues(column.id, event.target.value)}
													disabled={readOnly}
												/>
											)}
											{column.type === "Enum" && (
												<FormControl className={classes.inputBox}>
													<InputLabel id={`${column.label}-label`}>{column.label}</InputLabel>
													<Select
														disabled={readOnly}
														value={this.state.newData[column.id]}
														className={classes.inputBox}
														labelId={`${column.label}-label`}
														onChange={(event) => this.updateValues(column.id, event.target.value)}
													>
														{column.enumChoices!.map((item, index) => (
															<MenuItem value={String(item)}>{item}</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
											{column.type === "Date" && (
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<div>
														<KeyboardDatePicker
															disabled={readOnly}
															disableToolbar
															variant="inline"
															format="dd/MM/yyyy"
															margin="normal"
															id="date-picker-inline"
															label={column.label}
															value={new Date(this.state.newData[column.id])}
															onChange={(date) => this.updateValues(column.id, date)}
															KeyboardButtonProps={{
																"aria-label": "change date",
															}}
															InputLabelProps={{
																shrink: true,
																className: classes.inputPadding,
															}}
															className={[classes.inputBox, classes.filled].join(" ")}
														/>
													</div>
												</MuiPickersUtilsProvider>
											)}
											{column.type === "Timestamp"  && (
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<div>
														<KeyboardDatePicker
															disabled={readOnly}
															disableToolbar
															variant="inline"
															format="dd/MM/yyyy"
															margin="normal"
															id="date-picker-inline"
															label={column.label}
															value={this.state.newData[column.id]}
															onChange={(date) => this.updateDateTimeDate(column.id, date)}
															KeyboardButtonProps={{
																"aria-label": "change date",
															}}
															InputLabelProps={{
																shrink: true,
																className: classes.inputPadding,
															}}
															className={[classes.inputBox, classes.filled].join(" ")}
														/>
													</div>
													<div>
														<KeyboardTimePicker
															disabled={readOnly}
															margin="normal"
															id="time-picker"
															variant="inline"
															label={column.label}
															value={this.state.newData[column.id]}
															onChange={(time) => this.updateDateTimeTime(column.id, time)}
															KeyboardButtonProps={{
																"aria-label": "change time",
															}}
															InputLabelProps={{
																shrink: true,
																className: classes.inputPadding,
															}}
															className={[classes.inputBox, classes.filled].join(" ")}
														/>
													</div>
												</MuiPickersUtilsProvider>
											)}
											{column.type === "datetime-local"  && (
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<div>
														 <TextField
														 disabled={column.disabled}
														id="datetime-local"
														label={column.label}
														type="datetime-local"
														defaultValue={this.convertUnixTimestampToDateTime(this.state.newData[column.id])}
														className={[classes.inputBox, classes.filled].join(" ")}
														onChange={(time) => this.updateDateTimeTime(column.id, time)}
														InputLabelProps={{
														shrink: true,
														className: classes.inputPadding,
														}}
              />
													</div>
												</MuiPickersUtilsProvider>
											)}
											{column.type === "Image" && (
												<div
													style={{
														width: "200px",
														height: "auto",
														marginBottom: "10px",
													}}
												>
													{this.state.newData[column.id] && (
														<img
															style={{
																width: "100%",
																height: "auto",
															}}
															src={this.state.newData[column.id] || ""}
															alt="Img"
														/>
													)}

													{this.state.uploadingImage === 0 || this.state.uploadingImage === 100 ? (
														<ImageUploader addUrlToState={(url) => this.onUploadFinish(url, column.id)} />
													) : (
														<CircularProgress />
													)}
												</div>
											)}
											{column.type === "ManyToMany" && (
												<FormControl className={classes.inputBox}>
													<InputLabel id="demo-mutiple-chip-label">{column.label}</InputLabel>
													<Select
														labelId="demo-mutiple-chip-label"
														id="demo-mutiple-chip"
														multiple
														value={this.state.newData[column.id] || []}
														onChange={(event: any) => this.updateValues(column.id, event.target.value)}
														input={<Input id="select-multiple-chip" />}
														renderValue={(selected) => (
															<div className={classes.chips}>
																{this.state.selectableOptions[column.id] &&
																	(selected as string[]).map((value) => {
																		return (
																			<Chip
																				key={value}
																				label={
																					this.state.selectableOptions[column.id].find(
																						(x: any) => value === x.id
																					).name
																				}
																				className={classes.chip}
																			/>
																		);
																	})}
															</div>
														)}
														MenuProps={{
															PaperProps: {
																style: {
																	maxHeight: 48 * 4.5 + 8,
																	width: 250,
																},
															},
														}}
													>
														{this.state.selectableOptions[column.id] &&
															this.state.selectableOptions[column.id].map((item: any) => (
																<MenuItem key={item.id} value={item.id}>
																	{item.name}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											)}
											{column.type === "ReferenceInput" && (
												<FormControl className={classes.inputBox}>
													<InputLabel id={`${String(column.id)}-label`}>{column.label}</InputLabel>
													<Select
														labelId={`${String(column.id)}-label`}
														value={this.state.newData[column.id] || []}
														onChange={(event: any) => this.updateValues(column.id, event.target.value)}
													>
														{this.state.selectableOptions[column.id] &&
															this.state.selectableOptions[column.id].map((item: any) => (
																<MenuItem key={item.id} value={item.id}>
																	{item.name}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											)}
										</>
									))}
								</Grid>
							</Grid>
						</>
					)}
					{!readOnly && (
						<Button
							aria-label="save"
							variant="contained"
							startIcon={<SaveIcon />}
							style={{ float: "left", boxShadow: "none" }}
							onClick={() => this.saveView()}
						>
							Save
						</Button>
					)}
				</Container>
			</>
		);
	}
}

export default withStyles(styles)(EditView);
